@import "rsuite/dist/rsuite.css";

:root{
    --color-white: #FFFFFF;
    --color-gray: #EDEDED;
    --color-grey-regular: #E5E5E5;
    --color-grey-bold: #696969;
    --color-back: #7C7C7C;
    --color-blue: #3379FF;
    --color-black: #000000;
}

.navbar{
    display: flex;
    width: 100%;
    height: 4rem;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
}

.complaints_wrapper{
    margin-bottom: 4rem;
}

.banner_img{
    object-fit: cover;
    width: 100%;
    height: 100vw;
    max-height: 400px;
    z-index: 0;
}

.Complaints{
    position: relative;
    top: -75px;
    width: 90%;
    max-width: 991px;
    margin: auto;
    background-color: var(--color-white);
    z-index: 1;
    box-shadow: 0px 4px 40px 0px #0000000D;
}

h4{
    padding: 40px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
}

.Complaints .types{
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1px;
}

.complaints_wrapper .types a{
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: left;
    background-color: var(--color-gray);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: 1px;
    color: var(--color-grey-bold);
    animation: buttonOff 0.2s ease forwards;
}

.ymaps-2-1-79-map-copyrights-promo{
    display: none;
}

.inform{
    max-width: 991px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 4rem;
}

.inform p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}

.complaints_wrapper .types a:focus{
    background-color: var(--color-blue);
    color: var(--color-white);
    animation: buttonOn 0.2s ease forwards;
}

.complaints_wrapper .btn-wrapper{
    max-width: 991px;
    width: 90%;
    margin: auto;
}

.complaints_wrapper .btn-wrapper a{
    display: block;
    width: 100%;
    background-color: var(--color-blue);
    padding: 22px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: 1px;
    color: var(--color-white);
}

@keyframes buttonOn {
    from{
        background-color: var(--color-gray);
        color: var(--color-grey-bold);
    }
    to{
        background-color: var(--color-blue);
        color: var(--color-white);
    }
}

@keyframes buttonOff {
    from{
        background-color: var(--color-blue);
        color: var(--color-white);
    }
    to{
        background-color: var(--color-gray);
        color: var(--color-grey-bold);
    }
}

.complaint_views{
    position: relative;
}

.complaint_views .background_cover{
    position: absolute;
    height: 100%;
    width: 100vw;
    background-color: var(--color-grey-regular);
    z-index: -1;
}

.views_content{
    width: 90%;
    max-width: 991px;
    margin: auto;
    padding: 40px 0 50px 0;
    z-index: 1;
}

.views_content .wrapper{
    max-width: 100%;
    background-color: var(--color-white);
}

.views_content .wrapper .header{
    padding: 40px 0 5px 40px;

}

.views_content .wrapper .header .back{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.views_content .wrapper .header .back a img{
    margin-right: 1rem;
}

.views_content .wrapper .header .back span{
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1px;
    color: var(--color-back);
}

.disable{
    cursor: not-allowed;
    background-color: var(--color-gray) !important;
    color: var(--color-grey-bold) !important;
}

input[type='file']{
    display: none;
}

input[type='checkbox']{
    appearance: none;
    -webkit-appearance: none;
    height: 22px;
    width: 22px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #A5A5A5;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
}

input[type='checkbox']:after{
    content: url("../public/img/check.svg");
    display: none;
}

input[type='checkbox']:checked{
    border-color: #3379FF;
}

input[type='checkbox']:checked:after{
    display: inherit;
}


.map-container{
    margin-bottom: 20px;
    width: 100%;
    height: 60vw;
    max-height: 400px;
    position: relative;
}

.gmnoprint{
    display: none;
}

.rs-picker-toolbar-ranges{
    display: none;
}

.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start{
    max-width: 60vw;
    min-width: 247px;
    left: 5vw !important;
}

.rs-picker-daterange-calendar-group{
    display: contents;
}
.rs-calendar{
    width: 100%;
}

@media screen and (min-width: 1100px){
    .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start{
        position: absolute;
        /*transform: translateX(50%);*/
        left: auto !important;
        right: 50%;
    }
}
