.content{
    display: flex;
    margin-bottom: 40px;
}

.h5{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    width: 70%;
}

.line{
    border: 1px solid #3379FF;
    width: 5px;
    background-color: #3379FF;
    margin-right: 1rem;
}

.red{
    color: #DB5F5F;
}

.p{
    color: #525252;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 80%;
    margin-bottom: 5px;
    padding-left: 40px;
}

.pItalic{
    color: #ABABAB;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 5px;
    padding-left: 40px;
}

.placeName{
    height: 40px;
    border: 0.5px solid #A5A5A5;
    width: 75%;
    border-radius: 5px;
    outline-color: #A5A5A5;
    padding-left: 10px;
    margin-bottom: 20px;
    margin-left: 40px;
}

.wFull{
    width: 80%;
}


label{
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #525252;
    line-height: 22px;
    margin-bottom: 10px;
    padding-left: 40px;
}

.picture{
    margin-bottom: 20px;
    padding-left: 40px;
}

.selectionW input{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}

.selectionW p{
    display: inline;
    margin-bottom: 0;
}

.inputC{
    width: 80%;
    display: flex;
    align-items: center;
    padding-left: 40px;
    margin-bottom: 15px;
}

.placeNameT{
    height: 180px;
    border: 0.5px solid #A5A5A5;
    width: 75%;
    border-radius: 5px;
    outline-color: #A5A5A5;
    padding: 10px;
    margin-bottom: 50px;
    margin-left: 40px;
}

.PLzero{
    padding-left: 0;
}

.btnNext{
    background-color: #3379FF;
    width: 100%;
    padding: 25px 0;
    text-align: center;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
}

input[type='submit']{
    background-color: #3379FF;
    width: 100%;
    padding: 25px 0;
    text-align: center;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    border: none;
}

/*.width{*/
/*    width: 90%;*/
/*}*/

.dateWrapper{
    width: 80%;
    margin-left: 40px;
    margin-bottom: 20px;
}

.file{
    background-color:  #3379FF;
    width: 65%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
    margin-bottom: 20px;
}

.file img{
    display: inline;
    margin-right: 13px;
}

.file p{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}